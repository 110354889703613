// in src/App.js
import React from "react";
import { Admin, Resource } from "react-admin";
import { TransactionList } from "./transactions";
import { SearchList } from "./search";
import Dashboard from "./dashboard";
import dataProvider from "./dataProvider";
import { PostShow } from "./profile";
import Data from "./count";
import Basic from "./basic";
import Analytics, { AnalyticsList } from "./analytics";
import tutiTheme from "./theme";
import AppLayout from "./AppLayout"
// TODO(adonese): #6 maybe we should make a welcome screen in a better cleaner way
// but for now it works

const App = () => (
  <Admin
    title="Tuti Dashboard"
    theme={tutiTheme}
    layout={AppLayout}
    dataProvider={dataProvider}
    dashboard={Dashboard}
  >
    <Resource name="all" list={SearchList} />
    <Resource name="data" {...Data} />
    <Resource name="basic" {...Basic} />
    <Resource name="charts" list={AnalyticsList} />
  </Admin>
);

export default App;
