import { defaultTheme } from "react-admin";

const primary = { light: "#fb5607", main: "#fb5607", dark: "#fb5607" };
const secondary = { light: "#ff7807", main: "#ff7807", dark: "#ff7807" };
const error = { light: "#ff7807", main: "#ff7807", dark: "#ff7807" };

const tutiTheme = {
  ...defaultTheme,
  palette: {
    primary,
    secondary,
    error,
  },
};

export default tutiTheme;
