// in src/MyAppBar.js
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { AppBar } from "react-admin";

import logo from "./images/logo.png";

const useStyles = makeStyles({
  spacer: {
    flex: 1,
  },
  logo: {
    maxWidth: "40px",
    marginRight: "10px",
  },
});

const TutiAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar
      sx={{
        "& .RaAppBar-title": {
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
      }}
      {...props}
    >
      <img src={logo} alt="logo" className={classes.logo} />
      <Typography variant="h6" color="inherit" id="react-admin-title" />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default TutiAppBar;
