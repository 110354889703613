// in src/users.js
import React from "react";

import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  DateField,
} from "react-admin";

//TODO(adonese): #10 add more options and configurable search parameters, currently only supported is by terminalId
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="terminalId" alwaysOn />
  </Filter>
);

export const SearchList = (props) => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="ID" />
      <DateField source="CreatedAt" />
      <TextField source="terminalId" />
      <TextField source="responseMessage" />
      <TextField source="approvalCode" />
      <TextField source="tranAmount" />
      <TextField source="responseStatus" />
      <TextField source="responseCode" />
    </Datagrid>
  </List>
);
